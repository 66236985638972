import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`A typical programmer’s workflow looks like this:`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">{`Edit code.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Compile code (if you’re using a compiled language like Rust or C++).`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Run code / tests.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Step 3 helps you find errors during the development process,
and it’s useful to run it often. However, it can be quite distracting
to leave your editor, run some commands in a terminal (or your IDE),
and then return to the editor again.  `}</MDXTag>
<MDXTag name="p" components={components}>{`Here are some tips on how to automate steps 2 and 3:`}</MDXTag>
<MDXTag name="h3" components={components}>{`Use an editor plugin`}</MDXTag>
<MDXTag name="p" components={components}>{`Regardless of what editor you’re using (Vim / Emacs / VSCode etc.), there
probably exists a plugin for the language that you’re using. These can often
give you feedback directly next to the source file that you’re editing, whether
it be syntax errors or style violations.`}</MDXTag>
<MDXTag name="p" components={components}>{`Editors like Vim also provide hooks that are triggered when a file is saved,
which allows running arbitrary commands at the end of a save.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Use a test runner`}</MDXTag>
<MDXTag name="p" components={components}>{`Test runners (like `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://jestjs.io/"}}>{`Jest`}</MDXTag>{`, for example) can re-run tests
automatically when a source file changes. This can be quite nifty when you’re
debugging a broken test. All you need to do is edit the code and you
get instant feedback on whether it fixed the test or not.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Use entr`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://eradman.com/entrproject/"}}>{`Entr`}</MDXTag>{` provides a general mechanism to run
a command (or multiple commands) when the contents of certain watched files
change. This can be used to create tiny REPL-like environments for any language / editor
combination.`}</MDXTag>
<MDXTag name="p" components={components}>{`For example, if you are editing some Rust code, you can leave
the following command running in a terminal:`}</MDXTag>
<div className="gatsby-highlight" data-language="text"><pre style={{counterReset: 'linenumber NaN'}} className="language-text line-numbers"><code className="language-text">ls *.rs | entr -c -s 'rustc *.rs -o out &amp;&amp; ./out'</code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /></span></pre></div>

<MDXTag name="p" components={components}>{`and it will show you any compilation errors instantly when you
save a file. If the files compile correctly, it will show you
the output.`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  